"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useScript = (url) => {
    (0, react_1.useEffect)(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};
exports.default = useScript;
